import { render, staticRenderFns } from "./BaseHeader.vue?vue&type=template&id=e3c823b2&scoped=true&"
import script from "./BaseHeader.vue?vue&type=script&lang=js&"
export * from "./BaseHeader.vue?vue&type=script&lang=js&"
import style0 from "../scss/base-header.scss?vue&type=style&index=0&id=e3c823b2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e3c823b2",
  null
  
)

/* custom blocks */
import block0 from "../locales/base-header/de.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2FUsers%2Fpwm%2FDev%2Fbase-header%2Fsrc%2Fcomponents%2FBaseHeader.vue"
if (typeof block0 === 'function') block0(component)
import block1 from "../locales/base-header/en.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2FUsers%2Fpwm%2FDev%2Fbase-header%2Fsrc%2Fcomponents%2FBaseHeader.vue"
if (typeof block1 === 'function') block1(component)

export default component.exports